
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "BaseModal",
  props: {
    header: {
      type: String
    },
    variant: {
      type: String,
      default: "base",
      validator: (prop: string) => ["base", "danger", "success"].includes(prop)
    },
    size: {
      type: String,
      default: "base",
      validator: (prop: string) => ["base", "large"].includes(prop)
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    close: null
  },
  setup(props, context) {
    const onClose = () => {
      context.emit("close");
    };

    const sizeClasses = computed(() => {
      return {
        base: "sm:max-w-lg",
        large: "sm:max-w-5xl"
      }[props.size as "base" | "large"];
    });

    return {
      onClose,
      sizeClasses
    };
  }
});
