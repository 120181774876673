// generate random id for dom elements
export function createRandomPrefixedId(prefix: string) {
  return Math.random()
    .toString(36)
    .replace("0.", `${prefix}_`);
}

// remove hyphen from uuid
export function cleanUuid(uuid: string) {
  // replaces all occurences in string
  return uuid.replace(/-/g, "");
}

// insert dashes into uuid string
export function repairUuid(uuidString: string | string[]) {
  if (Array.isArray(uuidString)) uuidString = uuidString[0];
  // https://stackoverflow.com/questions/27172823/validate-uuid-without-divider
  // https://stackoverflow.com/questions/6981487/insert-hyphens-in-javascript
  return uuidString.replace(/(.{8})(.{4})(.{4})(.{4})(.+)/, "$1-$2-$3-$4-$5");
}

/**
 * Method to scroll y-axis into view port, if it's outside the viewport
 *
 * @param {Object} target - DOM Element
 * @param {Object} container - Scroll container
 */
// eslint-disable-next-line
export function scrollYIntoViewIfNeeded(target: any, container: any, behavior: "auto" | "smooth" = "auto") {
  // null check
  if (!target || !container) return;
  // Target is outside the viewport from the bottom
  if (target.getBoundingClientRect().bottom > container.getBoundingClientRect().bottom) {
    //  The bottom of the target will be aligned to the bottom of the visible area of the scrollable ancestor.
    target.scrollIntoView({ block: "end", behavior: behavior });
  } // Target is outside the view from the top
  else if (target.getBoundingClientRect().top < container.getBoundingClientRect().top) {
    // The top of the target will be aligned to the top of the visible area of the scrollable ancestor
    target.scrollIntoView({ block: "start", behavior: behavior });
  }
}

/**
 * add leading zero to single number
 * @param value Minute or Hour
 */
export function addLeadingZero(value: string) {
  if (value.length === 1) return "0" + value;
  else return value;
}

/**
 * create time string with leading zeros
 * @param hours
 * @param minutes
 */
export function createTimeString(hours: string, minutes: string) {
  const hourValue = addLeadingZero(hours);
  const minuteValue = addLeadingZero(minutes);
  return hourValue + ":" + minuteValue;
}
