<template>
  <teleport to="#modal">
    <BaseModal :visible="visible" :variant="'danger'" :header="header" @close="onClose">
      <template #body>
        {{ message }}
      </template>
      <template #footer>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <!-- !!! BUG: Click event fires twice if it is attached to button. !!! -->
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <BaseButton
              class="w-full justify-center sm:w-auto text-base sm:text-sm"
              @btn-click="onConfirmDelete"
              :loading="loading"
              :variant="'danger'"
              >Delete</BaseButton
            >
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <BaseButton class="w-full justify-center sm:w-auto text-base sm:text-sm" @btn-click="onClose"
              >Cancel</BaseButton
            >
          </span>
        </div>
      </template>
    </BaseModal>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseModal from "@/components/base/BaseModal.vue";

export default defineComponent({
  name: "DeleteConfirmationModal",
  components: {
    BaseModal
  },
  props: {
    header: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    close: null,
    confirm: null
  },
  setup(props, context) {
    const onConfirmDelete = () => {
      context.emit("confirm");
    };

    const onClose = () => {
      context.emit("close");
    };

    return { onConfirmDelete, onClose };
  }
});
</script>
