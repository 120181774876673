
import { defineComponent } from "vue";
import BaseModal from "@/components/base/BaseModal.vue";

export default defineComponent({
  name: "DeleteConfirmationModal",
  components: {
    BaseModal
  },
  props: {
    header: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  emits: {
    close: null,
    confirm: null
  },
  setup(props, context) {
    const onConfirmDelete = () => {
      context.emit("confirm");
    };

    const onClose = () => {
      context.emit("close");
    };

    return { onConfirmDelete, onClose };
  }
});
